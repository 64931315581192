<template>
    <div class="user-box">

        <div class="userbar">
			<div class="user-top-box">
				<div class="avatar">
					<img class="img" src="/img/user/avatar.jpg" />
				</div>
				<p class="userName"> {{ userInfo.username }} </p>
			</div>
		</div>

        <div class="user-list">
            <van-cell is-link @click="goTo('/user/password')" :title="$t('LAN.change_pwd')" />
            <van-cell is-link @click="goTo('/user/bindemail')" :value="userInfo.email != '' ? userInfo.email : '(未綁定)' " title="綁定郵箱"/>
            <van-cell is-link @click="priveShow=true" title="使用者服務協定"/>
        </div>

        <!-- 隐私政策弹窗 -->
        <van-popup v-model:show="priveShow" round :style="{ height: '90%', width: '90%', maxWidth: '440px' }">
            <iframe src="http://www.handzone.xyz/handzone/privacy/index.html?lang=zh_TW" frameborder="0"/>
        </van-popup>
        
        <div style="margin: 16px;">
            <van-button round block color="#6e87ea" @click="handleLogout">切換帳號</van-button>
        </div>

        <nav-bar />
    </div>


</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { requestInfo } from "@/service/user";
import { Toast } from 'vant'
import NavBar from '@/components/NavBar'

export default {
    name: 'my',
    setup() {
        const router = useRouter()
        const store = useStore()
        // const { proxy } = getCurrentInstance();

        const state = reactive({
            priveShow:false,
            userInfo: {
                username:"",
                email:"",
                googleName:"",
                fbName:"",
            }
        });

        onMounted(() => {
            // proxy.$SeoUpdate('seo标题','关键词1,关键词2,关键词3,关键词4,关键词5','网站描述--描述这个网站具体是围绕什么业务开展的')
            init()

            window.addEventListener('loginSuccess', function () {  
                init()
            }, false);
        });

        const init = async () => {
            console.log("username", store.getters.username)
            state.userInfo.username = store.getters.username;
            store.commit("setNeedLogin", true)
            if (store.getters.isLogin) {
                Toast.loading('加載中');
                const result = await requestInfo();
                state.userInfo.username = result.username;
                state.userInfo.email = result.email || "";
                state.userInfo.googleName = result.googleName || "";
                state.userInfo.fbName = result.fbName || "";
                store.dispatch("updateInfo", result);
                Toast.clear()
            }
        }
        
        const goTo = (url, params) => {
            router.push({ path: url, query: params})
        }

        const handleLogout = ()=> {
            store.dispatch('logout')
        }
        
        return {
            ...toRefs(state),
            goTo,
            handleLogout,
        }
    },
    components: {
        NavBar,
    },
}

</script>

<style lang="less" scoped>

.user-box {
    height: calc(var(--vh, 1vh) * 100);
}

iframe {
    height: 100%;
    width: 100%;
}
.content-scroll{
    width: 100%;
}

.user-list {
    text-align: left;
}

.userbar {
    height: 200px;
    position: relative;
    overflow: hidden;
    background: #6e87ea;
    .user-top-box {
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        font-size: 18px;
    }
    .avatar {
        width: 80px;
        height: 80px;
        margin: auto;
        padding: 3px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 0 5px #333;
        .img {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }
    p {
        text-align: center;
        color: #fff;
        letter-spacing: 2px;
    }
}
</style>